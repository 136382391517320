<template>
    <div class="card">
        <Toast />
        <form>
            <div class="p-grid p-fluid">
                <div class="p-col-12">
                    <h5>{{ $t('Applications.EditApplication.Heading') }}</h5>
                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Applications.EditApplication.Title') }}</label>
                            <InputText type="text" :placeholder="$t('Applications.EditApplication.TitlePlace')"
                                id="title2" v-model="title" @keydown.space="preventLeadingSpace"
                                :class="`${error.title ? 'p-invalid' : ''}`" maxlength="75"></InputText>
                            <div class="text-red">{{ error.title }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Applications.EditApplication.Description') }}</label>
                            <Textarea type="text" :placeholder="$t('Applications.EditApplication.DescriptionPlace')"
                                id="title2" v-model="description" @keydown.space="preventLeadingSpace"
                                :class="`${error.description ? 'p-invalid' : ''}`" maxlength="1500"></Textarea>
                            <div class="text-red">{{ error.description }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Applications.EditApplication.MaxApplicants') }}</label>
                            <InputText type="text" :placeholder="$t('Applications.EditApplication.MaxApplicantsPlace')"
                                id="title2" @keydown.space="preventLeadingSpace" v-model="max_user_apply"
                                :class="`${error.max_user_apply ? 'p-invalid' : ''}`"
                                onkeypress="return /[0-9]/i.test(event.key)"
                                onkeyup="if (event.srcElement.value.charAt(0) == '0') { event.srcElement.value = event.srcElement.value.slice(1); }"
                                maxlength="7">
                            </InputText>
                            <div class="text-red">{{ error.max_user_apply }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Applications.EditApplication.Country') }}</label>
                            <Dropdown v-model="country_id" :options="dropdownCountryValues" optionValue="id"
                                optionLabel="country_name" :placeholder="$t('Applications.EditApplication.Select')"
                                :class="`${error.country_id ? 'p-invalid' : ''}`" />
                            <div class="text-red">{{ error.country_id }}</div>
                            <!-- <div class="text-red">{{ error.country_id }}</div> -->
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="subtitle2">
                                {{ $t('Applications.EditApplication.Upload') }}
                                <!-- <span class="img-info">(파일 형식: jpg,jpeg,png )(이미지 너비 924px and 고저 860px 및 크기 1MB )</span> -->
                            </label>
                            <div :class="`${error.file ? 'custom-select-invalid' : 'custom-select'}`">
                                <span v-if="!fileName">{{ $t('Applications.EditApplication.ChooseFile') }}</span>
                                <span v-else>{{ fileName }}</span>
                                <input type="file" class="select-file" v-on:change="onFileChange" />
                                <Button :label="$t('Applications.EditApplication.fileupload')"
                                    class="SelectBtn n-wrap" />
                            </div>
                            <div class="text-red" v-show="render2">최대 파일 크기는 5MB여야 합니다.</div>
                            <div class="text-red">{{ error.file }}</div>
                            <div class="text-red" v-show="render1">유효하지 않은 파일 형식</div>
                            <img :src="image_path" alt="이미지를 사용할 수 없음" class="product-image" />
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Applications.EditApplication.ApplicationStartDate') }}</label>
                            <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy.mm.dd"
                                placeholder="YYYY/MM/DD H:M:S" v-model="release_date"
                                :class="`${error.release_date ? 'p-invalid' : ''}`" :min-date="new Date()"
                                :showTime="true" :showSeconds="true"></Calendar>
                            <div class="text-red">{{ error.release_date }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Applications.EditApplication.ApplicationEndDate') }}</label>
                            <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy.mm.dd"
                                placeholder="YYYY/MM/DD H:M:S" v-model="expire_date"
                                :class="`${error.expire_date ? 'p-invalid' : ''}`" :min-date="new Date()"
                                :showTime="true" :showSeconds="true"></Calendar>
                            <div class="text-red">{{ error.expire_date }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Applications.EditApplication.VotingstartDate') }}</label>
                            <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy.mm.dd"
                                placeholder="YYYY/MM/DD H:M:S" v-model="voting_start_date"
                                :class="`${error.voting_start_date ? 'p-invalid' : ''}`" :min-date="new Date()"
                                :showTime="true" :showSeconds="true"></Calendar>
                            <div class="text-red">{{ error.voting_start_date }}</div>
                        </div>
                    </div>

                    <div class="p-grid p-formgrid p-mb-3">
                        <div class="p-col-12 p-mb-2 p-lg-5 p-mb-lg-0 p-field">
                            <label for="title2">{{ $t('Applications.EditApplication.VotingEndDate') }}</label>
                            <Calendar :showIcon="true" :showButtonBar="true" dateFormat="yy.mm.dd"
                                placeholder="YYYY/MM/DD H:M:S" v-model="voting_end_date"
                                :class="`${error.voting_end_date ? 'p-invalid' : ''}`" :min-date="new Date()"
                                :showTime="true" :showSeconds="true"></Calendar>
                            <div class="text-red">{{ error.voting_end_date }}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-d-flex p-jc-end p-ai-center" style="float: left">
                <Button :label="$t('Applications.EditApplication.Save')" icon="pi pi-check" iconPos="left"
                    class="p-button p-button-sm p-mr-2 p-mb-2" @click="AddVoting"></Button>
            </div>

            <div class="p-d-flex p-jc-end p-ai-center">
                <div>
                    <Button :label="$t('Applications.EditApplication.Back')" icon="pi pi-replay" iconPos="left"
                        class="p-button p-button-outlined p-button-sm p-mr-2 p-mb-2" @click="$router.go(-1)"></Button>
                </div>
            </div>

            <br />
        </form>
    </div>
</template>
<script>
import axios from 'axios';
import VotemanagementService from '../../../service/API/SuperAdmin/VotemanagementService.js';
import ValidateEditVote from '../../../validations/VoteManagement/ValidateEditVote';
import { useRoute } from 'vue-router';
import moment from 'moment';
export default {
    data() {
        return {
            bulletinCategoryDetail: {},
            title: '',
            country_id: '',
            max_user_apply: '',
            image: '',
            description: '',
            release_date: '',
            expire_date: '',
            voting_start_date: '',
            voting_end_date: '',
            status: 'active',

            file: '',
            files: '',
            fileName: '',
            fileExtension: '',
            filesExtension: '',
            fileExtensions: '',
            filesExtensions: '',
            dropdownCountryValues: '',
            dropdownCountryValue: null,
            image_path: '',

            expose: '',
            // status:'',
            render2: false,
            render1: false,
            formData: new FormData(),
            error: {},
        };
    },
    created() {
        this.votemanagementService = new VotemanagementService();
    },

    mounted() {
        const route = useRoute();
        console.log(route.params);
        // this.votemanagementService
        //     .getCountrydropdown()
        //     .then((data) => {
        //         this.dropdownCountryValues = data.data.data.country;
        //         // this.products = data;
        //         this.loading1 = false;
        //         // this.products.forEach((customer) => (customer.createdDate = new Date(customer.createdDate)));
        //         console.log(this.dropdownCountryValues);
        //     })
        //     .catch((err) => console.log(err));
        // this.UserComments(this.$route.params.id)
        this.votemanagementService.viewVoteManagement(this.$route.params.id).then((res) => {
            this.title = res.data.data.application[0].title;
            this.country_name = res.data.data.application[0].country_name;
            this.voting_end_date = this.FormatDateAdd(res.data.data.application[0].voting_end_date);
            this.voting_start_date = this.FormatDateAdd(res.data.data.application[0].voting_start_date);
            this.status = res.data.data.application[0].status;
            this.expire_date = this.FormatDateAdd(res.data.data.application[0].expire_date);
            this.description = res.data.data.application[0].description;
            this.release_date = this.FormatDateAdd(res.data.data.application[0].release_date);
            this.max_user_apply = res.data.data.application[0].max_user_apply;
            this.applicantCount = res.data.data.applicantCount;
            this.selectedApplicantCount = res.data.data.selectedApplicantCount;
            this.country_id = res.data.data.application[0].country_id;
            this.image_path = res.data.data.application[0].image_path;
            this.getCountry()

            // console.log(res.data.data.manager.country_name)
        });
    },
    methods: {
        getCountry() {

            this.votemanagementService.getCountrydropdown(this.country_id).then((res) => {

                this.dropdownCountryValues = res.data.data.country;
                console.log(res);
            });
        },
        FormatDate(value) {
            if (value) {
                return moment(String(value)).format('YYYY/MM/DD  h:mm:ss');
            }
        },
        FormatDateAdd(value) {
            if (value) {
                return moment.utc(value).format('YYYY/MM/DD HH:mm:ss');
            }
        },
        FormatDateEdit(value) {
            if (value) {
                return moment(value).format('YYYY-MM-DD HH:mm:ss');
            }
        },
        preventLeadingSpace(e) {
            // only prevent the keypress if the value is blank
            if (!e.target.value) e.preventDefault();
            // otherwise, if the leading character is a space, remove all leading white-space
            else if (e.target.value[0] == ' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
        // onFileChange(e) {
        //     var files = e.target.files || e.dataTransfer.files;
        //     if (!files.length) return;
        //     this.file = files[0];
        //     var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
        //     if (!allowedExtensions.exec(this.file.name)) {
        //         this.render1 = true;
        //         return false;
        //     } else {
        //         this.render1 = false;
        //         this.fileName = this.file.name;
        //         this.formData.append('image', files[0]);
        //     }
        //     this.fileExtension = this.fileName.replace(/^.*\./, '');
        //     console.log(this.fileName);
        // },
        onFileChange(e) {
            var files = e.target.files || e.dataTransfer.files;
            // if (!files.length) return;
            this.file = files[0];
            var allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
            if (!allowedExtensions.exec(this.file.name)) {
                this.render1 = true;
                this.render2 = false;
            } else {
                let reader = new FileReader
                reader.readAsDataURL(this.file)
                reader.onload = (e) => {
                    let url = e.target.result
                    this.image_url = url
                }
                this.render1 = false;
                if (this.file.size > 5242880) {
                    // alert('파일이 너무 큽니다  ');
                    this.render2 = true;
                } else {
                    this.render2 = false;
                    this.fileName = this.file.name;
                    this.formData.append('image', files[0]);
                    // this.upload(e)
                }
            }
            this.fileExtension = this.fileName.replace(/^.*\./, '');
            console.log(this.fileName);
        },

        AddVoting() {
            console.log(this.fileName);
            let vcheckData = {
                title: this.title,
                description: this.description,
                // type: this.dropdownValueType == null ? '' : 'something',
                voting_start_date: this.voting_start_date,
                voting_end_date: this.voting_end_date,
                release_date: this.release_date,
                expire_date: this.expire_date,
                max_user_apply: this.max_user_apply.toString(),
                country_id: this.country_id.toString(),
                file: this.fileName == '' ? '' : 'something',
            };
            const { isInvalid, error } = ValidateEditVote(vcheckData);
            if (isInvalid) {
                this.error = error;
                console.log(error);
            } else {
                // console.log(this.code);
                this.formData.append('id', this.$route.params.id);
                this.formData.append('title', this.title);
                this.formData.append('max_user_apply', this.max_user_apply);
                this.formData.append('description', this.description);
                this.formData.append('country_id', this.country_id);
                this.formData.append('voting_end_date', this.FormatDateEdit(this.voting_end_date));
                this.formData.append('voting_start_date', this.FormatDateEdit(this.voting_start_date));
                this.formData.append('status', this.status);
                this.formData.append('release_date', this.FormatDateEdit(this.release_date));
                this.formData.append('expire_date', this.FormatDateEdit(this.expire_date));
                // this.formData.append('type', this.dropdownValueType?.name);
                // this.formData.append('status', this.dropdownValue.name===undefined ? this.dropdownValue :this.dropdownValue.name);
                console.log(this.formData);
                return axios.put('/asnhist-dev/api/v1/admin/application/edit', this.formData).then(() => {
                    this.$router.push({ name: 'Votemanagementlist' });
                    setTimeout(() => {
                        this.$toast.add({ severity: 'info', summary: '확인되었습니다.', detail: '지원서가 성공적으로 업데이트되었습니다.', life: 3000 });
                    }, 1000);
                });
            }
        },
    }
};
</script>

<style scoped>
.p-fluid .p-button {
    width: auto;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}

.product-image {
    padding-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    width: 150px;
    height: 100px;

}

button#Save {
    margin-left: 13px;
    margin-top: 15px;
}

div#double-text {
    display: flex;
    justify-content: space-between;
}

.custom-select {
    position: relative;
    border: 1px solid #cecece;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.custom-select-invalid {
    position: relative;
    border: 1px solid red;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
}

.select-file {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0%;
    z-index: 5;
    opacity: 0;
}

.SelectBtn {
    max-width: 100px;
}

.custom-select span {
    max-width: 140px;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.p-button {
    background: #000000;
    border: 1px solid #0a0a0a;
    color: white;
}
</style>